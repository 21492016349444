import type {
  PermissionType,
  RegionType,
  RoleType
} from '~/features/admin/api/types'
import type { UserType } from '~/features/auth/api/types'

export const initialState_admin: initialStateType_admin = {
  viewAsUser: null,
  selectedUser: null,
  selectedUsers: [],
  selectedRoles: [],
  selectedPermissions: [],
  selectedRegions: [],
  sortingCategories: [],
  dialog: {
    user: false,
    addUser: false,
    roles: false,
    permissions: false,
    regions: false,
    save: false,
    editing: false,
    editingMultiple: false,
    newEntry: false
  },

  managementViews: {
    permissions: false,
    roles: false
  },

  // Service messages
  CreateServiceMessageDialogShow: false
}

export type initialStateType_admin = {
  viewAsUser: UserType | null
  selectedUser: UserType | null
  selectedUsers: UserType[]
  selectedRoles: RoleType[]
  selectedPermissions: PermissionType[]
  selectedRegions: RegionType[]
  sortingCategories: sortingCategoriesType[]
  dialog: {
    user: boolean
    addUser: boolean
    roles: boolean
    permissions: boolean
    regions: boolean
    save: boolean
    editing: boolean
    editingMultiple: boolean
    newEntry: boolean
  }
  managementViews: {
    permissions: boolean
    roles: boolean
  }

  // Service messages
  CreateServiceMessageDialogShow: boolean
}

export type sortingCategoriesType = {
  name: string
  checked: boolean
}

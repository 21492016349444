import type { permissionsTypes } from '~/features/auth/store/state'
import { useAdminStore } from '~/features/admin/store'
import useAuthStore from '~/features/auth/store'

export default function (permissions: permissionsTypes[]) {
  /**
   * View as user functionality
   */
  const { viewAsUser } = useAdminStore()
  const { user: _user } = useAuthStore()

  const user = computed(() => {
    return {
      ..._user,
      permissions: viewAsUser?.permissions || _user?.permissions || [],
      roles: viewAsUser?.roles || _user?.roles || []
    }
  })

  /**
   * If the permissions array is empty, then the user has access to the route
   */
  if (permissions && permissions?.length === 0) return true

  if (user.value) {
    const permissionsArray = [...permissions].map((x) =>
      x
        .toLocaleLowerCase()
        .replace(/[^a-zA-Z0-9]/g, '')
        .trim()
    )

    const userPermissions_Roles = [...user.value.permissions].map((x) =>
      x?.name
        ?.toLocaleLowerCase()
        .replace(/[^a-zA-Z0-9]/g, '')
        .trim()
    )
    return userPermissions_Roles.some((x) => permissionsArray.includes(x))
  }

  return false
}
